import './index.scss';
import { loadingPaying } from './loading/paying';
import { create } from './numpad';
import type { NumpadNotify } from './numpad/numpad';
import { NumpadEventType } from './numpad/numpad';
import AliPay from './pay/alipay';
import { prePay, PrePayExtraInfo } from './pay/pre-pay';
import WxPay from './pay/wxpay';
import { preCheck } from './pre-check';
import { CHANNEL_TYPE, QrInfo } from './pre-check/constants';
import { Errors, OperationalError } from './utils/error';

let webPay:
  | {
      pay: WxPay | AliPay;
      prePayExtra: PrePayExtraInfo;
    }
  | undefined;

const numpad = create();

function showError(errMsg: string, hide: boolean | number = false) {
  const ele = document.querySelector<HTMLDivElement>('#overlay');
  if (!ele) {
    return;
  }

  const msgEle = ele.querySelector<HTMLElement>('.msg');

  if (!msgEle) {
    return;
  }

  msgEle.innerText = errMsg;
  ele.style.display = 'flex';

  let delay = 0;
  if (typeof hide === 'boolean') {
    if (hide) {
      delay = 1000;
    }
  } else {
    delay = hide;
  }

  if (delay > 0) {
    setTimeout(() => {
      ele.style.display = 'none';
    }, delay);
  }
}

function catchError(e: Error | OperationalError) {
  if (e instanceof OperationalError) {
    console.warn(e.toString());
  }
  console.error(e);

  if (e instanceof Errors.PlatformNotSupport) {
    showError('请在微信或支付宝中打开');
  } else if (e instanceof Errors.PageInValid) {
    showError('进入支付失败, 请确认地址是否正确');
  } else if (e instanceof Errors.AuthMaxRetry) {
    showError('授权认证失败');
  } else if (e instanceof Errors.LoadScriptFailed) {
    showError('网络错误, 请重试');
  } else if (e instanceof Errors.PayFailed) {
    showError('支付失败请重试', 3000);
  }
}

numpad.subscribe<NumpadNotify>((v) => {
  if (v.type !== NumpadEventType.submit) {
    return;
  }

  if (v.disabled) {
    return;
  }

  // 支付环境还没准备好
  if (!webPay) {
    return;
  }

  // 支付前 禁止再次点击支付按钮
  numpad.updateSubmitBtn(true);
  // 显示正在支付中
  loadingPaying.show();

  prePay(v.value.cent, webPay.prePayExtra)
    .then((result) => {
      loadingPaying.hide();
      // hide 偶发失败, 延迟使其生效
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve(result);
        }, 200);
      });
    })
    .then((result) => {
      console.log('prePay result:', result);
      return webPay?.pay.pay(result);
    })
    .then((result) => {
      console.log('Pay result:', result);

      numpad.updateSubmitBtn(false);
    })
    .catch((e) => {
      catchError(e);

      numpad.updateSubmitBtn(false);
      loadingPaying.hide();
    });
});

function updatePage(qrInfo: QrInfo) {
  // 显示组织名称
  const nameEle = document.querySelector<HTMLDivElement>('.merchant .name');
  if (nameEle) {
    nameEle.innerText = qrInfo.orgName;
  }

  // 显示组织logo
  const logoEle = document.querySelector<HTMLImageElement>('.merchant .logo');
  if (logoEle) {
    if (qrInfo.orgLogo) {
      logoEle.src = qrInfo.orgLogo;
    }
  }

  // 移除loading
  document.querySelector('body')?.classList.remove('loading');
}

preCheck()
  .then(({ channel, customerId, qrInfo }) => {
    updatePage(qrInfo);

    if (channel === CHANNEL_TYPE.WXPAY) {
      webPay = {
        pay: new WxPay(),
        prePayExtra: {
          channel,
          customerId,
          ...qrInfo,
        },
      };
    } else {
      webPay = {
        pay: new AliPay(),
        prePayExtra: {
          channel,
          customerId,
          ...qrInfo,
        },
      };
    }

    return webPay;
  })
  .catch(catchError);
