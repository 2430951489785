class Notify {
  protected list: Function[] = [];

  public subscribe<T = any>(fn: (v: T) => void) {
    this.list.push(fn);

    return {
      unsubscribe: () => {
        const index = this.list.findIndex((v) => {
          return v === fn;
        });

        if (index >= 0) {
          this.list.splice(index, 1);

          return true;
        }

        return false;
      },
    };
  }

  protected notify(v: any) {
    this.list.forEach((fn) => {
      fn(v);
    });
  }
}

export default Notify;
