import { QrInfo, VALID_CHANNEL_TYPE } from '../pre-check/constants';
import { request } from '../utils/request';

type PrePayExtraInfo = {
  channel: VALID_CHANNEL_TYPE;
  customerId: string;
} & Pick<QrInfo, 'orgId' | 'merchantId'>;

async function prePay(totalAmount: number, extra: PrePayExtraInfo) {
  return request({
    method: 'POST',
    path: '/webpays',
    body: {
      ...extra,
      source: 'QRCODE',
      productName: '扫码消费',
      totalAmount,
    },
  });
}

export { prePay };
export type { PrePayExtraInfo };
