import { preloadImg } from '../utils/helper';

class LoadingPaying {
  private imgUrl = '/static/img/paying.gif';
  private id = '__loadingPaying__';

  private ele: HTMLImageElement | undefined;

  public constructor() {
    setTimeout(async () => {
      this.ele = await preloadImg('/static/img/paying.gif');
    }, 1000);
  }

  public show() {
    const divEle = document.createElement('div');
    divEle.id = this.id;

    let loadingEle = this.ele;
    if (!loadingEle) {
      loadingEle = new Image();
      loadingEle.src = this.imgUrl;
    }
    divEle.appendChild(loadingEle);

    document.body.appendChild(divEle);
  }

  public hide() {
    document.querySelector(`#${this.id}`)?.remove();
  }
}

const loadingPaying = new LoadingPaying();

export { loadingPaying };
