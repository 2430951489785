import { getPressValue } from './helper';
import Notify from './Notify';

enum NumpadEventType {
  'press' = 'press',
  'submit' = 'submit',
}

type NumpadNotify =
  | {
      type: NumpadEventType.press;
      pressValue: string;
      value: {
        val: number;
        cent: number;
      };
    }
  | {
      type: NumpadEventType.submit;
      disabled: boolean;
      value: {
        val: number;
        cent: number;
      };
    };

class Numpad extends Notify {
  public static checkIsDisable(amount: string) {
    return ['', '0', '0.', '0.0', '0.00'].includes(amount);
  }

  private amountDisplay: HTMLDivElement;

  public constructor(
    private numpadContainer: Element,
    private submitBtn: HTMLButtonElement,
    private placeholderEle: HTMLDivElement,
  ) {
    super();

    // TODO: 适配不同元素ID
    const ele = this.placeholderEle.querySelector<HTMLDivElement>('#order-price');
    if (!ele) {
      throw new Error('need price show element');
    }

    this.amountDisplay = ele;

    this.updateValue('');
    this.updateSubmitBtn(true);

    this.init();
  }

  public get value() {
    let amount = this.amountDisplay.innerText;

    let v = parseFloat(amount);

    return {
      val: v,
      cent: Math.floor(v * 100),
    };
  }

  public pressKey(val: string) {
    if (val === 'x') {
      this.delVal();
      return;
    }

    let amount = this.addVal(val);
    this.updateValue(amount);
    this.updateSubmitBtn(Numpad.checkIsDisable(amount));
  }

  public hidePad() {
    this.numpadContainer.classList.add('hidden');
  }

  public showPad() {
    this.numpadContainer.classList.remove('hidden');
  }

  public updateSubmitBtn(disable: boolean) {
    if (disable) {
      this.disableSubmitBtn();
    } else {
      this.enableSubmitBtn();
    }
  }

  private addVal(val: string) {
    let amount = this.amountDisplay.innerText;

    if (amount === '' && val === '.') {
      return '0.';
    }

    if (amount === '0' && val !== '.') {
      return val;
    }

    if (val === '.' && amount.indexOf('.') > 0) {
      return amount;
    }

    // reached 2 decimal points
    if (amount.length > 3 && amount.charAt(amount.length - 3) === '.') {
      return amount;
    }

    if (amount.length >= 7) {
      return amount;
    }

    return amount + val;
  }

  private delVal() {
    const amount = this.amountDisplay.innerText;
    let newAmount = amount.substring(0, amount.length - 1);

    if (!newAmount) {
      newAmount = '';
    }

    this.updateValue(newAmount);
    this.updateSubmitBtn(Numpad.checkIsDisable(newAmount));
  }

  private disableSubmitBtn() {
    this.submitBtn.classList.add('disable');
    this.submitBtn.disabled = true;
  }

  private enableSubmitBtn() {
    this.submitBtn.classList.remove('disable');
    this.submitBtn.disabled = false;
  }

  private init() {
    this.numpadContainer.addEventListener('touchstart', (e) => {
      const pressValue = getPressValue(e);

      if (pressValue) {
        this.pressKey(pressValue);

        this.notify({ type: NumpadEventType.press, pressValue, value: this.value });
      }
    });

    this.submitBtn.addEventListener('touchstart', (e) => {
      const disabled = this.submitBtn.disabled;
      this.notify({ type: NumpadEventType.submit, disabled, value: this.value });
    });
  }

  private updateValue(amount: string) {
    this.amountDisplay.innerText = amount;

    if (amount === '') {
      this.placeholderEle.classList.add('placeholder');
    } else {
      this.placeholderEle.classList.remove('placeholder');
    }
  }
}

export default Numpad;

export { NumpadEventType };
export type { NumpadNotify };
