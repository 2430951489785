import AliPay from '../pay/alipay';
import WxPay from '../pay/wxpay';
import { Errors } from '../utils/error';
import { request } from '../utils/request';
import { checkIsAlipay, checkIsWx } from '../utils/user-agent';
import type { QrInfo } from './constants';
import {
  ALIPAY_CUSTOMER_ID_KEY,
  CHANNEL_TYPE,
  VALID_CHANNEL_TYPE,
  WECHAT_CUSTOMER_ID_KEY,
} from './constants';

const getChannelType = (() => {
  let env = CHANNEL_TYPE.notSupport;

  if (checkIsWx()) {
    env = CHANNEL_TYPE.WXPAY;
  } else if (checkIsAlipay()) {
    env = CHANNEL_TYPE.ALIPAY;
  }

  return (): CHANNEL_TYPE => {
    return env;
  };
})();

function getAuthCode(channelType: VALID_CHANNEL_TYPE, usp: URLSearchParams) {
  return usp.get(channelType === CHANNEL_TYPE.ALIPAY ? 'auth_code' : 'code');
}

function code2id(
  qrCode: string,
  authCode: string,
  channelType: VALID_CHANNEL_TYPE,
): Promise<string> {
  return request({
    method: 'GET',
    path: '/qrcodes/auth',
    qs: {
      qrCode,
      authCode,
      channelType,
    },
  }).catch((e) => {
    throw new Errors.AuthFailed(e);
  });
}

// 将当前URL替换成未授权之前的URL, 防止用户刷新页面时用老的code授权
function replaceUrl(channelType: VALID_CHANNEL_TYPE) {
  const u = channelType === CHANNEL_TYPE.ALIPAY ? AliPay.getCleanUrl() : WxPay.getCleanUrl();

  history.replaceState({}, '', u.href);
}

function setStorageCustomerId(channelType: VALID_CHANNEL_TYPE, value: string) {
  sessionStorage.setItem(
    channelType === CHANNEL_TYPE.ALIPAY ? ALIPAY_CUSTOMER_ID_KEY : WECHAT_CUSTOMER_ID_KEY,
    value,
  );
}

function getStorageCustomerId(channelType: VALID_CHANNEL_TYPE): string | undefined {
  const id = sessionStorage.getItem(
    channelType === CHANNEL_TYPE.ALIPAY ? ALIPAY_CUSTOMER_ID_KEY : WECHAT_CUSTOMER_ID_KEY,
  );

  if (id && id !== 'undefined') {
    return id;
  }
}

function redirectToAuth(channelType: VALID_CHANNEL_TYPE, jumpNumber = 0) {
  if (channelType === CHANNEL_TYPE.ALIPAY) {
    AliPay.redirectToAuth(ALIPAY_APPID, `${jumpNumber + 1}`);
  } else {
    WxPay.redirectToAuth(WECHAT_APPID, `${jumpNumber + 1}`);
  }
}

async function getCustomerId(
  channelType: VALID_CHANNEL_TYPE,
  qrCode: string,
  maxRetry = 2,
): Promise<string | undefined> {
  const usp = new URLSearchParams(location.search);

  // 从 sessionStorage 获取 customerId
  const storageId = getStorageCustomerId(channelType);

  if (storageId) {
    return storageId;
  }

  let jumpNumber = 0;
  // 判断 state 参数 (实际保存为 跳转次数), 是否超过限制
  const state = usp.get('state');
  if (state) {
    jumpNumber = parseInt(state, 10);
  }

  // 跳转次数过多, 抛出错误
  if (jumpNumber >= maxRetry) {
    throw new Errors.AuthMaxRetry({
      jumpNumber,
      channelType,
      qrCode,
      maxRetry,
    });
  }

  // 通过授权认证 authCode 获取
  const authCode = getAuthCode(channelType, usp);
  if (authCode) {
    const id = await code2id(qrCode, authCode, channelType).catch((e) => {
      console.warn(e);
      return;
    });

    if (id) {
      setStorageCustomerId(channelType, id);
      replaceUrl(channelType);
      return id;
    }
  }

  // 重新跳转 授权认证
  redirectToAuth(channelType, jumpNumber);
  return;
}

function getQrCode() {
  const path = location.pathname;

  const qrCode = path.replace(/.*\//, '');

  if (!qrCode) {
    throw new Errors.PageInValid({ path });
  }

  return qrCode;
}

async function getQrInfo(qrCode: string): Promise<QrInfo> {
  const data = await request({
    method: 'GET',
    path: '/qrcodes/orgInfo',
    qs: { qrCode },
  });

  return {
    qrCode,
    ...data,
  };
}

function checkIsValidChannel(): VALID_CHANNEL_TYPE {
  let env = getChannelType();

  if (env === CHANNEL_TYPE.notSupport) {
    throw new Errors.PlatformNotSupport();
  }

  return env;
}

async function preCheck(): Promise<{
  qrInfo: QrInfo;
  customerId: string;
  channel: VALID_CHANNEL_TYPE;
}> {
  const channel = checkIsValidChannel();
  const qrCode = getQrCode();
  const customerId = await getCustomerId(channel, qrCode);
  if (!customerId) {
    throw new Errors.AuthFailed({ channel, qrCode });
  }

  const qrInfo = await getQrInfo(qrCode);

  return {
    qrInfo,
    customerId,
    channel,
  };
}

export { preCheck };
