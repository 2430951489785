export const ALIPAY_CUSTOMER_ID_KEY = 'aliPay_customer_id_key';
export const WECHAT_CUSTOMER_ID_KEY = 'wechat_customer_id_key';

export enum CHANNEL_TYPE {
  WXPAY = 'WXPAY',
  ALIPAY = 'ALIPAY',

  notSupport = '不支持',
}

export type VALID_CHANNEL_TYPE = CHANNEL_TYPE.WXPAY | CHANNEL_TYPE.ALIPAY;

interface QrInfo {
  qrCode: string;
  orgId: string;
  merchantId: string;
  orgName: string;
  orgLogo: string;
}

export type { QrInfo };
