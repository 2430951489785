const preloadImg = (url: string): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    if (img.complete) {
      resolve(img);
    } else {
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      img.onabort = reject;
    }
    img.src = url;
  });
};

export { preloadImg };
