import Numpad from './numpad';

function create() {
  const numpadContainer = document.querySelector('#numpad-container');
  const submitBtn = document.querySelector<HTMLButtonElement>('#btn-submit');
  const priceEle = document.querySelector<HTMLDivElement>('.price');

  if (!numpadContainer) {
    throw new Error('numpadContainer');
  }

  if (!submitBtn) {
    throw new Error('submitBtn');
  }

  if (!priceEle) {
    throw new Error('priceEle');
  }

  const numpad = new Numpad(numpadContainer, submitBtn, priceEle);

  return numpad;
}

export { create };
