function getParentDataSet(e: Event) {
  return (e.target as any).parentNode?.dataset;
}

function getDataSet(e: Event) {
  return (e.target as any).dataset;
}

function getPressValue(e: Event): undefined | string {
  const dataset = getDataSet(e);

  if (dataset?.pressValue !== undefined) {
    return dataset.pressValue;
  }

  return getParentDataSet(e)?.pressValue;
}

export { getDataSet, getPressValue };
