export function checkIsWx() {
  let ua = window.navigator.userAgent.toLowerCase();
  if (/MicroMessenger/i.test(ua)) {
    return true;
  }
  return false;
}

export function checkIsAlipay() {
  let ua = window.navigator.userAgent.toLowerCase();
  if (/Alipay/i.test(ua)) {
    return true;
  }
  return false;
}
