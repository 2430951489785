import { checkIsAlipay, checkIsWx } from '../utils/user-agent';
import { Errors } from '../utils/error';

// https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#3
const WECHANT_JS_SDK_URL = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
// https://myjsapi.alipay.com/alipayjsapi/index.html
const ALIPAY_JS_SDK_URL =
  'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';

async function loadScript(url: string) {
  return new Promise<void>((resolve, reject) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = () => {
      resolve();
    };
    script.onerror = (e) => {
      reject(new Errors.LoadScriptFailed(e));
    };
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

async function loadAlipaySdk() {
  return loadScript(ALIPAY_JS_SDK_URL);
}

async function loadWechatSdk() {
  return loadScript(WECHANT_JS_SDK_URL);
}

async function loadJSSdk() {
  if (checkIsWx()) {
    await loadWechatSdk();
  } else if (checkIsAlipay()) {
    await loadAlipaySdk();
  } else {
    throw new Errors.PlatformNotSupport({ userAgent: navigator.userAgent });
  }
}

export { loadJSSdk, loadAlipaySdk, loadWechatSdk };
