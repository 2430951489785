import { Errors } from '../utils/error';
import { loadJSSdk } from './load-sdk';

interface PayRequestData {
  extra: string;
  transactionId: string;
  outTradeNo: string;
  form: string;
}

class WxPay {
  public static getCleanUrl(url = location.href.replace(/#.*/, '')) {
    // 将原有的授权参数删除
    // 替换 hostname 为后台授权跳转域名
    // 添加 后台授权跳转 env 参数
    let temp = new URL(url);
    temp.searchParams.delete('appid');
    temp.searchParams.delete('redirect_uri');
    temp.searchParams.delete('response_type');
    temp.searchParams.delete('scope');
    temp.searchParams.delete('state');

    // 删除老的 code
    temp.searchParams.delete('code');
    temp.searchParams.delete('env');

    return temp;
  }

  // eslint-disable-next-line max-params
  public static redirectToAuth(
    appId: string,
    state = '',
    scope = 'snsapi_base',
    redirectUrl: string = location.href.replace(/#.*/, ''),
  ) {
    const cleanUrl = WxPay.getCleanUrl(redirectUrl);

    // 替换 hostname 并添加 env
    cleanUrl.hostname = WECHAT_AUTH_REDIRECT_DOMAIN;
    cleanUrl.searchParams.append('env', WECHAT_AUTH_REDIRECT_ENV);

    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize';
    url += '?appid=' + appId;
    url += '&redirect_uri=' + encodeURIComponent(cleanUrl.href);
    url += '&response_type=code';
    url += '&scope=' + scope;
    url += '&state=' + state + '#wechat_redirect';
    location.replace(url);
  }

  public initialized: Promise<void>;

  public constructor(isLoadSdk = true) {
    this.initialized = Promise.all([
      isLoadSdk ? loadJSSdk() : Promise.resolve(),
      new Promise<void>((resolve) => {
        if (typeof WeixinJSBridge === 'undefined') {
          document.addEventListener(
            'WeixinJSBridgeReady',
            () => {
              resolve();
            },
            false,
          );
        } else {
          resolve();
        }
      }),
    ]).then(() => {});

    this.hide();
  }

  public async pay(data: PayRequestData) {
    await this.initialized;

    const extra = JSON.parse(data.extra);

    return new Promise((resolve, reject) => {
      WeixinJSBridge.invoke('getBrandWCPayRequest', extra, (res: any) => {
        const result = { res, outTradeNo: data.outTradeNo, data, errorMessage: res.resultCode };

        switch (res.err_msg) {
          case 'get_brand_wcpay_request:ok':
            resolve(result);
            break;
          case 'get_brand_wcpay_request:cancel':
            reject(new Errors.PayCancel(result));
            break;
          default:
            reject(new Errors.PayFailed(result));
        }
      });
    });
  }

  private async hide() {
    await this.initialized;

    WeixinJSBridge.call('hideToolbar');
    WeixinJSBridge.call('hideOptionMenu');
  }
}

export default WxPay;
export type { PayRequestData };
