import { Errors } from '../utils/error';
import { loadJSSdk } from './load-sdk';

interface PayRequestData {
  extra: string;
  transactionId: string;
  outTradeNo: string;
  form: string;
}

class AliPay {
  public static getCleanUrl(url = location.href.replace(/#.*/, '')) {
    // 将原有的授权参数删除
    let temp = new URL(url);
    temp.searchParams.delete('app_id');
    temp.searchParams.delete('redirect_uri');
    temp.searchParams.delete('scope');
    temp.searchParams.delete('state');

    // 删除老的 code
    temp.searchParams.delete('auth_code');
    temp.searchParams.delete('source');

    return temp;
  }

  // eslint-disable-next-line max-params
  public static redirectToAuth(
    appId: string,
    state = '',
    scope = 'auth_base',
    redirectUrl: string = location.href.replace(/#.*/, ''),
  ) {
    const cleanUrl = AliPay.getCleanUrl(redirectUrl);

    let url = 'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm';
    url += '?app_id=' + appId;
    url += '&scope=' + scope;
    url += '&redirect_uri=' + encodeURIComponent(cleanUrl.href);
    url += '&state=' + state;

    location.replace(url);
  }

  public initialized: Promise<void>;

  public constructor(isLoadSdk = true) {
    this.initialized = Promise.all([
      isLoadSdk ? loadJSSdk() : Promise.resolve(),

      new Promise<void>((resolve) => {
        if (typeof AlipayJSBridge === 'undefined') {
          document.addEventListener(
            'AlipayJSBridgeReady',
            () => {
              resolve();
            },
            false,
          );
        } else {
          resolve();
        }
      }),
    ]).then(() => {
      return undefined;
    });
  }

  public async init() {
    await this.initialized;
  }

  public async pay(data: PayRequestData) {
    await this.initialized;

    let tradeNO = data.extra.substring(2);

    const res = await ap.tradePay({ tradeNO });
    const result = {
      outTradeNo: data.outTradeNo,
      data,
      res,
      tradeNO,

      errorMessage: res.resultCode,
    };

    switch (res.resultCode) {
      case '9000': // 订单支付成功
        return result;
      case '6001': // 用户中途取消
        throw new Errors.PayCancel(result);
      default:
        throw new Errors.PayFailed(result);
    }
  }
}

export default AliPay;
